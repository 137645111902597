define(['ePopup', 'app', 'simpleSlider', 'siteObj'], (ePopup, app, simpleSlider, siteObj) => {
  
  class UGCPopUp {

    /**
     *
     * @param element
     * @param options
     */
    constructor(element, options) {

      this.element = element ? element : '.ugc';
      this.popupOptions = {
        thumbnailTitle: '[data-js-element="ugc_title"]',
        thumbnailImage: '[data-js-element="ugc_thumbnailItemImage"]',
        thumbnailContainer: '[data-js-element="ugc_thumbnailContainer"]',
        popupImage: '[data-json-element="ugc_popupAssets"]',
        popupContent: '[data-json-element="ugc_popupContent"]',
        imageDataAttr: 'data-ugc-standard',
        popupClassName: 'ugc_popupOverlay'
      };

      this.popupOptions = options ? options : this.popupOptions;

      this.init();
    }

    /**
     * init
     */
    init() {
      this.getElements();
      this.thumbnailHandler();
      this.nextImageHandler();
      this.mobileDevice = siteObj.siteIsMobile;
    }

    /**
     * getElements
     */
    getElements() {
      this.el = document.querySelector(this.element);
      this.ugcCarousel = this.el.querySelector('[data-js-element="ugc_popupCarousel"]');
      this.popups = this.el.querySelector('[data-js-element="ugc_popup"]');
      this.thumbnailSlider = this.el.querySelector('[data-js-element="ugcThumbnailCarousel"]');
      this.thumbnailImage = this.el.querySelector(this.popupOptions.thumbnailImage);
      this.thumbnailContainer = this.el.querySelector(this.popupOptions.thumbnailContainer);
      this.popupImages = this.el.querySelectorAll(this.popupOptions.popupImage);
      this.popupContent = this.el.querySelectorAll(this.popupOptions.popupContent);
      this.ugcSlider = new simpleSlider('.ugc_popup');

      if (this.thumbnailSlider) this.thumbnailCarousel();
    }

    /**
     * thumbnailCarousel
     */
    thumbnailCarousel() {

      this.thumbnailOptions = {
        links: '.simpleSlider_nav .simpleSlider_slide-nav-link',
        wrapper: '.ugcThumbnailCarousel_slide',
        prev: '[data-js-element="ugcThumbnailCarousel_buttonPrev"]',
        next: '[data-js-element="ugcThumbnailCarousel_buttonNext"]',
        slide: '.ugcThumbnailCarousel_slideItem',
        slideCaption: '.simpleSlider_slide-caption',
        slideNav: '.simpleSlider_slide-nav',
        loop: true
      };

      this.ugcThumbnailCarousel = new simpleSlider('.ugcThumbnailCarousel', this.thumbnailOptions);
    }

    /**
     * thumbnailHandler
     */
    thumbnailHandler() {
      let SELF = this;
      this.thumbnailContainer.addEventListener('click', (e) => {
        const thumbnail = e.target;
        if (thumbnail.classList.contains('ugc_thumbnailItemImage')) SELF.initThumbnail(thumbnail);
      });
    }

    /**
     *  initThumbnail
     * @param thumbnail
     */
    initThumbnail(thumbnail) {
      this.current = app.element.getAttribute('data-ugc-thumbnail', thumbnail);
      this.current = Number(this.current);
      this.initPopup(this.current);
      this.ugcSlider.total = this.popupImages.length;
    }

    /**
     * nextImageHandler
     */
    nextImageHandler() {
      let SELF = this;

      // mobile needs both click and touchend event listener

      window.addEventListener('click', () => {
        SELF.slideIndex();
      });

      window.addEventListener('touchend', () => {
        SELF.slideIndex();
      });

      window.addEventListener('keydown', () => {
        SELF.slideIndex();
      });
    }

    /**
     * slideIndex
     */
    slideIndex() {
      if (this.current !== this.ugcSlider.current) {
        this.current = this.ugcSlider.current;
        this.requestSliderImage(this.current);
      }
    }

    /**
     * initPopup
     * @param sliderNumber
     * @returns {string}
     */
    initPopup(sliderNumber) {
      this.ePop = new ePopup(this.popups, this.popupOptions.popupClassName, true);
      this.requestSliderImage(sliderNumber);
      this.initSlider(sliderNumber, this.ugcSlider);

      if (this.mobileDevice) {
        let jsPopup = document.querySelector(`.${this.popupOptions.popupClassName} .js-popup-container`);
        return (jsPopup.style.top = '0px');
      }
    }

    /**
     * initSlider
     * @param sliderNumber
     */
    initSlider(sliderNumber) {
      this.ugcSlider.options.loop = true;
      this.ugcSlider.current = sliderNumber;
    }

    /**
     * requestSliderImage
     * @param thumbnailNumber
     * @returns {*|string}
     */
    requestSliderImage(thumbnailNumber) {
      this.current = thumbnailNumber;
      this.standardImage = this.popupImages[thumbnailNumber];
      this.sliderImageUrl = app.element.getAttribute(this.popupOptions.imageDataAttr, this.standardImage);
      this.popupAvatar = app.element.getAttribute('data-ugc-avatar', this.standardImage);
      this.popupUsername = app.element.getAttribute('data-ugc-username', this.standardImage);
      this.popupCaption = app.element.getAttribute('data-ugc-caption', this.standardImage);

      const slideMarkup = `<div class="ugc_popupCarouselSlider">
                     <img class="ugc_popupImage" src=${this.sliderImageUrl}>
                     <div class="ugc_popupContent">
                        <div class="ugc_userContainer">
                    <img class="ugc_popupUserAvatar" role="presentation" aria-hidden="true" alt="" src=${this.popupAvatar}>
                    <div class="ugc_popupUsername">${this.popupUsername}
                    </div>
                    </div>
                    <div class="ugc_popupContentText">${this.popupCaption}</div>
                     </div>
                </div>`;

      this.ugcCarousel.innerHTML = slideMarkup;
    }

  }

  if (document.querySelector('.ugc')) return new UGCPopUp();
  return UGCPopUp;
});
